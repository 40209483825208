import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { loadScript } from '@paypal/paypal-js'
import Rails from '@rails/ujs'
import * as Sentry from '@sentry/browser'
import { toast } from './utils/toast'

const INPUT_ERROR_CLASS = 'input__field--error'

export default class extends Controller {
  static targets = [
    'card',
    'name',
    'nameError',
    'error',
    'form',
    'submit',
    'applePayWalletSelector',
    'googlePayWalletSelector',
    'paypalWalletSelector',
    'creditCardInputFields',
    'paypalButton',
    'loader',
    'walletPaymentAmount',
  ]
  static values = {
    paypalSetupToken: String,
    paypalIdToken: String,
    paypalClientId: String,
    stripeSetupIntent: String,
    cardMethodSerialized: String,
    applePayMethodSerialized: String,
    googlePayMethodSerialized: String,
    paypalMethodSerialized: String
  }

  submitEventListener = this.submit.bind(this)

  async connect() {
    if (this.stripeSetupIntentValue) {
      if (!window.Stripe) {
        this.loadStripe().then(() => {
          this.initializeStripe()
        })
      } else {
        this.initializeStripe()
      }
    } else {
      this.hideLoader()
    }

    if (this.paypalSetupTokenValue && this.paypalIdTokenValue) {
      this.initializePaypal()
    }
  }

  loadStripe() {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script')

      scriptElement.src = 'https://js.stripe.com/v3/'
      scriptElement.onload = () => resolve()
      scriptElement.onerror = () => reject()

      document.head.appendChild(scriptElement)
    })
  }

  async initializePaypal() {
    loadScript({ 'client-id': this.paypalClientIdValue }).then((paypal) => {
      paypal
        .Buttons({
          locale: 'en_US',
          style: {
            color: 'gold',
            size: 'large',
            label: 'pay',
            height: 54,
          },
          createVaultSetupToken: () => this.paypalSetupTokenValue,
          onApprove: ({ vaultSetupToken }) => {
            this.paypalButtonTarget.classList.add('disabled')
            this.handlePaypalPaymentMethod(vaultSetupToken)
          },
          onClick: () => {
            this.paypalButtonTarget.classList.add('disabled')
          },

          onCancel: () => {
            this.paypalButtonTarget.classList.remove('disabled')
          },

          onError: (error) => {
            this.paypalButtonTarget.classList.remove('disabled')
            Sentry.captureException(error)
          },
        })
        .render(this.paypalButtonTarget)
        .then(() => {
          this.paypalWalletSelectorTarget.classList.remove('hidden')
          // The PayPal button will be rendered in an html element with the ID
          // 'paypal-button'. This function will be called when the PayPal button
          // is set up and ready to be used
        })
    })
  }

  initializeStripe() {
    const stripeKey = document.querySelector('meta[name="stripe-key"]').getAttribute('content')

    this.stripe = Stripe(stripeKey, { locale: 'en' })

    let elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: '/styles/css-variables.scss.css',
        },
      ],
    })

    // Payment intents are for processing payments that require action
    this.payment_intent = this.data.get('payment-intent')

    this.element.addEventListener('submit', this.submitEventListener)

    // Get CSS variables values
    const inputColor = getComputedStyle(document.documentElement).getPropertyValue(
      '--color-on-background',
    )
    const inputPlaceholderColor = getComputedStyle(document.documentElement).getPropertyValue(
      '--color-on-background-rgb',
    )
    const inputWeight = getComputedStyle(document.documentElement).getPropertyValue(
      '--font-weight-base',
    )
    const inputFont = getComputedStyle(document.documentElement).getPropertyValue(
      '--font-family-base',
    )

    // Setup regular payments
    this.card = elements.create('card', {
      hidePostalCode: false,
      hideIcon: true,
      style: {
        base: {
          color: inputColor,
          fontFamily: inputFont,
          fontWeight: inputWeight,
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: `rgba(${inputPlaceholderColor}, 0.5)`,
          },
        },
      },
    })
    this.card.mount(this.cardTarget)
    this.card.addEventListener('change', this.changed.bind(this))
    this.selectedPaymentMethod = this.cardMethodSerializedValue

    if (Number.isInteger(this.walletPaymentAmount())) {
      this.walletTimeout = setTimeout(() => {
        this.submitTarget.disabled = false
        document.querySelectorAll('.payment-method__container')[0].classList.remove('hidden')

        if (this.hasLoaderTarget) {
          this.hideLoader()
        }
      }, 3000)

      this.initializeWallets()
    } else {
      this.submitTarget.disabled = false
    }
  }

  walletPaymentAmount() {
    if (this.hasWalletPaymentAmountTarget) {
      return parseInt(this.walletPaymentAmountTarget.dataset.paymentAmount)
    } else {
      return null
    }
  }

  initializeWallets() {
    this.paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Subscription',
        amount: this.walletPaymentAmount(),
      },
      requestPayerName: true,
      requestPayerEmail: false,
      disableWallets: ['browserCard'],
    })

    this.paymentRequest.canMakePayment().then((result) => {
      clearTimeout(this.walletTimeout)
      document.querySelectorAll('.payment-method__container')[0].classList.remove('hidden')

      if (this.hasLoaderTarget) {
        this.hideLoader()
      }

      this.submitTarget.disabled = false

      if (result) {
        this.showWalletSelector(result)
      }
    })

    this.paymentRequest.on('paymentmethod', (event) => {
      const paymentMethod = event.paymentMethod

      if (paymentMethod) {
        this.submitTarget.disabled = true

        if (this.stripeSetupIntentValue) {
          this.setupNewCard({
            payment_method: paymentMethod.id,
          })
        } else {
          this.handleStripePaymentMethod(paymentMethod.id)
        }
      }

      event.complete('success') // Completes the transaction and closes Google/Apple pay
    })
  }

  showWalletSelector(result) {
    if (result.applePay) {
      this.applePayWalletSelectorTarget.classList.remove('hidden')
      this.googlePayWalletSelectorTarget.remove()
    }

    if (result.googlePay) {
      this.googlePayWalletSelectorTarget.classList.remove('hidden')
      this.applePayWalletSelectorTarget.remove()
    }
  }

  showPaypalButton() {
    this.submitTarget.classList.add('hidden')
    this.paypalButtonTarget.classList.remove('hidden')
  }

  hidePaypalButton() {
    this.submitTarget.classList.remove('hidden')
    this.paypalButtonTarget.classList.add('hidden')
  }

  selectPaymentMethod(event) {
    // Highlight selected method
    Array.from(document.querySelectorAll('.payment-method__container')).forEach((e) =>
      e.classList.remove('active'),
    )
    event.target.closest('.payment-method__container').classList.add('active')

    this.selectedPaymentMethod = event.target.value

    if (this.selectedPaymentMethod === this.cardMethodSerializedValue) {
      this.creditCardInputFieldsTarget.classList.remove('hidden')
      this.hidePaypalButton()
    } else if (this.selectedPaymentMethod === this.paypalMethodSerializedValue) {
      this.creditCardInputFieldsTarget.classList.add('hidden')
      this.showPaypalButton()
    } else {
      this.creditCardInputFieldsTarget.classList.add('hidden')
      this.hidePaypalButton()
    }
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ''
    }
  }

  keydown(event) {
    if (event.keyCode == 13) {
      // Catch Enter key's form submission and process as submit
      event.preventDefault()
      this.submit(event)
    }

    if (this.nameTarget.value !== '') {
      this.nameTarget.classList.remove(INPUT_ERROR_CLASS)
      this.nameErrorTarget.textContent = ''
    }
  }

  submit(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.selectedPaymentMethod === this.applePayMethodSerializedValue || this.selectedPaymentMethod === this.googlePayMethodSerializedValue) {
      this.handleAsWalletPayment()
    } else {
      this.handleAsStripeCreditCardPayment()
    }
  }

  handleAsWalletPayment() {
    // Take current amount to be paid and pass it to the payment request right before showing it
    this.paymentRequest.update({
      total: {
        label: 'Subscription',
        amount: this.walletPaymentAmount(),
      },
    })
    this.paymentRequest.show()
  }

  handleAsStripeCreditCardPayment() {
    if (this.nameTarget.value == '' && this.hasNameErrorTarget) {
      this.nameTarget.classList.add(INPUT_ERROR_CLASS)
      this.nameErrorTarget.textContent = 'Name on card is required.'
      return
    }

    // One time payments
    if (this.payment_intent) {
      this.handleCardPayment()

      // Updating card with setup intent
    } else if (this.stripeSetupIntentValue) {
      this.setupNewCard({
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.nameTarget.value,
          },
        },
      })

      // Subscriptions simply tokenize the payment method and redirect to payment page if SCA required
    } else {
      // Disable Pay & Subscribe button
      this.submitTarget.disabled = true

      this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.nameTarget.value,
          },
        })
        .then((result) => {
          if (result.paymentMethod) {
            this.handleStripePaymentMethod(result.paymentMethod.id)
          } else {
            this.submitTarget.disabled = false
          }
        })
    }
  }

  setupNewCard(data) {
    this.submitTarget.disabled = true

    this.stripe.confirmCardSetup(this.stripeSetupIntentValue, data).then((result) => {
      if (result.error) {
        if (
          this.selectedPaymentMethod === this.applePayMethodSerializedValue ||
          this.selectedPaymentMethod === this.googlePayMethodSerializedValue
        ) {
          toast(result.error.message)

          Sentry.withScope((scope) => {
            scope.setExtra('event', result.error)
            Sentry.captureMessage('Wallet payment method error')
          })
        }

        this.errorTarget.textContent = result.error.message
        this.submitTarget.disabled = false
      } else {
        this.handleStripePaymentMethod(result.setupIntent.payment_method)
      }
    })
  }

  handleStripePaymentMethod(payment_method_id) {
    this.handlePaymentMethod('stripe', payment_method_id)
  }

  handlePaypalPaymentMethod(payment_method_id) {
    this.handlePaymentMethod('killbill', payment_method_id)
  }

  handlePaymentMethod(processor, payment_method_id) {
    this.element.removeEventListener('submit', this.submitEventListener)

    this.addHiddenField('parameters[processor]', processor)
    this.addHiddenField('parameters[card_token]', payment_method_id)

    Rails.fire(this.formTarget, 'submit')
    this.submitTarget.disabled = true
  }

  addHiddenField(name, value) {
    let hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', name)
    hiddenInput.setAttribute('value', value)
    this.formTarget.appendChild(hiddenInput)
  }

  handleCardPayment() {
    // Handle an existing payment that needs confirmation
    this.stripe.confirmCardPayment(this.payment_intent).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        Turbo.clearCache()
        Turbo.visit('/')
      }
    })
  }

  hideLoader() {
    this.loaderTarget.classList.add('hidden')
  }
}
